<template>
  <LoadingOverlay :loading="loading">
    <!-- Top 10 Holdings -->
    <div class="table-responsive">
      <table class="table sidebar">
        <thead>
          <tr>
            <th colspan="2">{{ holdingData.header }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in holdingData.items" :key="`item_${index}`">
            <th>
              {{ item.key }}
            </th>
            <td>{{ formatNumber(item.value || 0, 2) }}%</td>
          </tr>
          <tr
            v-for="(item, index) in holdingData.footer"
            :key="`item_${index}`"
          >
            <th>
              {{ item.key }}
            </th>
            <td>{{ item.value || 0 }}</td>
          </tr>
        </tbody>
        <caption v-if="!shareClass.fund?.is_fund_type_dbc">
          <h5 v-if="loadingExportHoldings">Downloading...</h5>
          <a href="#" @click.prevent="downloadCSV" v-else
            >Export Full Holdings</a
          >
        </caption>
      </table>
    </div>
    <!-- /.table-responsive -->
  </LoadingOverlay>
</template>

<script setup>
import { computed, ref } from 'vue';
import LoadingOverlay from '@/components/ui/LoadingOverlay.vue';
import { formatNumber } from '@/services/utils';
import { useFundProfile } from '../../composable/useFundProfile';

// Define props
const props = defineProps({
  shareClassData: {
    type: Object,
    default: () => ({}),
  },
  shareClass: {
    type: Object,
    default: () => ({}),
  },
  topHoldings: {
    type: Object,
    default: () => ({}),
  },
  ticker: {
    type: String,
    required: true,
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

// Computed property for holding data
const holdingData = computed(() => {
  const isFundTypeDBC = props.shareClass?.fund?.is_fund_type_dbc;

  if (isFundTypeDBC) {
    return {
      header: 'Top Companies (%)',
      items: [...(props.topHoldings?.allocations || [])],
      footer: [],
    };
  } else {
    return {
      header: 'Top 10 Portfolio Holdings (%)',
      items: [...(props.topHoldings?.allocations || [])],
      footer: [
        {
          key: 'Top 10 Holdings',
          value: `${props.topHoldings?.total || '-'}%`,
        },
        {
          key: '# Portfolio Holdings',
          value:
            (props.shareClassData?.equity_vs_bond?.data_value?.bond_no || 0) +
            (props.shareClassData?.equity_vs_bond?.data_value?.equity_no || 0),
        },
      ],
    };
  }
});

const { fetchExportHoldings, loadingExportHoldings, exportHoldings } =
  useFundProfile();

const downloadCSV = async () => {
  await fetchExportHoldings(props.ticker);

  const rows = exportHoldings.value.data.map((row) =>
    Object.values(row)
      .map((value) => `"${value}"`) // Wrap values in quotes for safety
      .join(',')
  );

  const csvContent = [exportHoldings.value.header, ...rows].join('\n');
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', 'holdings.csv');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
</script>
