<!-- Home.vue -->
<template>
  <div id="content">
    <LoadingOverlay :loading="isLoading">
      <div>
        <Header
          :shareClass="shareClass"
          :relatedIndexes="relatedIndexes"
        ></Header>
        <div class="container">
          <div class="row">
            <div class="col-xs-12 col-md-5 col-lg-5 col-xl-4 charts-tab show">
              <Current :shareClass="shareClass"></Current>
              <div class="col-spacer"></div>
              <FundInfo
                :shareClass="shareClass"
                :fundProfileData="fundProfileData"
              ></FundInfo>
              <div class="col-spacer"></div>
              <CapitalAndLeverage
                :shareClass="shareClass"
                :shareClassData="shareClassData"
              ></CapitalAndLeverage>
              <div class="col-spacer"></div>
              <DicountData :shareClassData="shareClassData"></DicountData>
              <div class="col-spacer"></div>
              <ZStat :shareClassData="shareClassData"></ZStat>
              <div class="col-spacer"></div>
              <DistributionData
                :shareClass="shareClass"
                :shareClassData="shareClassData"
                :fundProfileData="fundProfileData"
              ></DistributionData>
              <div class="col-spacer"></div>
              <TaxDividendData
                :taxDividendData="taxDividend"
                :loading="loadingTaxDividend"
              ></TaxDividendData>
            </div>
            <div class="col-xs-12 col-md-7 col-lg-7 col-xl-8 graphs-tab">
              <GrowthChart
                :growthData="growth10k"
                :loading="loadingGrowth10k"
              ></GrowthChart>
              <PremiumDiscountChart
                :discountData="priceChartData"
                :loading="loadingPriceChartData"
              ></PremiumDiscountChart>
              <NavPriceChart
                :navPriceData="priceChartData"
                :loading="loadingPriceChartData"
              ></NavPriceChart>
              <IndicatedYieldChart
                :yieldData="priceChartData"
                :loading="loadingPriceChartData"
              ></IndicatedYieldChart>
              <TotalReturn
                :reinvestedPriceData="reinvestedPriceData"
                :cashPriceData="cashPriceData"
                :ticker="ticker"
                :loading="loadingReinvestedPriceData || loadingCashPriceData"
              ></TotalReturn>
            </div>
            <!-- /.col -->
          </div>
          <!-- /.row -->
          <!-- Ad Space Banner -->
          <div class="row">
            <div class="col">
              <section class="component is-visible">
                <div class="mock-ad-space banner"><div>Ad Space</div></div>
              </section>
              <!-- /.component -->
            </div>
            <!-- /.col -->
          </div>
          <!-- /.row -->
          <div class="row">
            <div class="col-xs-12 col-md-5 col-lg-5 col-xl-4 charts-tab show">
              <DistributionLevel
                :shareClassData="shareClassData"
              ></DistributionLevel>
              <div class="col-spacer"></div>
              <VolumeAndLiquidity
                :shareClassData="shareClassData"
              ></VolumeAndLiquidity>
              <div class="col-spacer"></div>
              <CorrelationAndVolatility
                :shareClass="shareClass"
                :shareClassData="shareClassData"
              ></CorrelationAndVolatility>
              <div class="col-spacer"></div>
              <NavBeta
                :shareClass="shareClass"
                :shareClassData="shareClassData"
              ></NavBeta>
              <div class="col-spacer"></div>
              <BondSpecificData
                :shareClass="shareClass"
                :shareClassData="shareClassData"
              ></BondSpecificData>
              <div class="col-spacer"></div>
              <Earnings
                :shareClass="shareClass"
                :shareClassData="shareClassData"
              ></Earnings>
              <div class="col-spacer"></div>
              <TopHoldings
                :topHoldings="topHoldings"
                :shareClass="shareClass"
                :shareClassData="shareClassData"
                :ticker="ticker"
                :loading="loadingTopHoldings"
              ></TopHoldings>
            </div>
            <!-- /.col -->
            <div class="col-xs-12 col-md-7 col-lg-7 col-xl-8 graphs-tab">
              <AnnualizedTotalReturn
                :ticker="ticker"
                :reinvestedPriceData="reinvestedPriceData"
                :loading="loadingReinvestedPriceData"
              ></AnnualizedTotalReturn>
              <CalendarTotalReturn
                :ticker="ticker"
                :reinvestedPriceData="reinvestedPriceData"
                :loading="loadingReinvestedPriceData"
              ></CalendarTotalReturn>
              <DistributionHistory
                :dividendHistory="dividendHistory"
                :ticker="ticker"
                :loading="loadingDividendHistory"
              ></DistributionHistory>

              <div class="row">
                <div
                  class="col-xs-12 col-md-6"
                  v-for="(item, index) in firstBreakDownItems"
                  :key="`item_${index}`"
                >
                  <DonutChart :items="item.items" :title="item.title" />
                </div>
                <!-- /.col -->
              </div>
              <!-- /.row -->
            </div>
            <!-- /.col -->
          </div>
          <!-- /.row -->
          <div class="row align-items-end" v-if="hasSecondBreakDownItems">
            <div
              class="col-xs-12 col-md-4"
              v-for="(item, index) in secondBreakDownItems"
              :key="`item_${index}`"
            >
              <DonutChart :items="item.items" :title="item.title" />
            </div>
          </div>
          <!-- /.row -->
          <div class="row" v-if="!isEmpty(uniiShare)">
            <div class="col-xs-12 col-md-6">
              <UiiShare
                :uniiShare="uniiShare"
                :loading="loadingUniiShare"
              ></UiiShare>
            </div>
            <!-- /.col -->
            <div class="col-xs-12 col-md-6">
              <RelUnii
                :uniiShare="uniiShare"
                :loading="loadingUniiShare"
              ></RelUnii>
            </div>
            <!-- /.col -->
          </div>
          <!-- /.row -->
          <div class="row" v-if="!isEmpty(earnings)">
            <div class="col-xs-12 col-md-6">
              <EarningsShare
                :earnings="earnings"
                :loading="loadingEarnings"
              ></EarningsShare>
            </div>
            <!-- /.col -->
            <div class="col-xs-12 col-md-6">
              <EarningsCoverage
                :earnings="earnings"
                :loading="loadingEarnings"
              ></EarningsCoverage>
            </div>
            <!-- /.col -->
          </div>
          <!-- /.row -->
          <div class="row">
            <div class="col-xs-12 col-md-5 col-lg-5 col-xl-4 charts-tab show">
              <UniversalAvgDiscountData
                :shareClass="shareClass"
                :fundProfileData="fundProfileData"
                :loading="loadingFundProfileData"
              ></UniversalAvgDiscountData>
            </div>
            <!-- /.col -->
            <div class="col-xs-12 col-md-7 col-lg-7 col-xl-8 graphs-tab">
              <section class="component spacing-top-none is-visible">
                <div class="mock-ad-space rectangle"><div>Ad Space</div></div>
              </section>
              <!-- /.component -->
            </div>
            <!-- /.col -->
          </div>
        </div>
      </div>
    </LoadingOverlay>
  </div>
</template>

<script setup>
import Current from './components/sub-components/Current.vue';
import FundInfo from './components/sub-components/FundInfo.vue';
import CapitalAndLeverage from './components/sub-components/CapitalAndLeverage.vue';
import DicountData from './components/sub-components/DicountData.vue';
import ZStat from './components/sub-components/ZStat.vue';
import DistributionData from './components/sub-components/DistributionData.vue';
import TaxDividendData from './components/sub-components/TaxDividendData.vue';
import GrowthChart from './components/chart-components/GrowthChart.vue';
import PremiumDiscountChart from './components/chart-components/PremiumDiscountChart.vue';
import NavPriceChart from './components/chart-components/NavPriceChart.vue';
import IndicatedYieldChart from './components/chart-components/IndicatedYieldChart.vue';
import TotalReturn from './components/sub-components/TotalReturn.vue';
import DistributionLevel from './components/chart-components/DistributionLevel.vue';
import VolumeAndLiquidity from './components/sub-components/VolumeAndLiquidity.vue';
import CorrelationAndVolatility from './components/sub-components/CorrelationAndVolatility.vue';
import NavBeta from './components/sub-components/NavBeta.vue';
import BondSpecificData from './components/sub-components/BondSpecificData.vue';
import Earnings from './components/sub-components/Earnings.vue';
import TopHoldings from './components/sub-components/TopHoldings.vue';
import AnnualizedTotalReturn from './components/chart-components/AnnualizedTotalReturn.vue';
import CalendarTotalReturn from './components/chart-components/CalendarTotalReturn.vue';
import DistributionHistory from './components/sub-components/DistributionHistory.vue';
import UiiShare from './components/chart-components/UiiShare.vue';
import EarningsShare from './components/chart-components/EarningsShare.vue';
import EarningsCoverage from './components/chart-components/EarningsCoverage.vue';
import RelUnii from './components/chart-components/RelUnii.vue';
import UniversalAvgDiscountData from './components/sub-components/UniversalAvgDiscountData.vue';
import LoadingOverlay from '@/components/ui/LoadingOverlay.vue';
import DonutChart from '@/components/charts/DonutChart.vue';

import isEmpty from 'ramda/src/isEmpty'; // Import only isEmpty

import Header from './components/Header.vue';
import { useFundProfile } from './composable/useFundProfile';
import { onBeforeMount, computed, watch, ref } from 'vue';
import { useRoute } from 'vue-router';
import { formatNumber } from '@/services/utils';

// Import reactive loading states from useFundProfile
const {
  fetchShareClass,
  fetchShareClassData,
  fetchTaxDividend,
  fetchUniiShare,
  fetchEarnings,
  fetchRelatedIndexes,
  fetchTopHoldings,
  fetchGrowth10k,
  fetchPriceChartData,
  fetchReinvestedPriceData,
  fetchCashPriceData,
  fetchDividendHistory,
  fetchFundProfileData,

  loadingShareClass,
  loadingShareClassData,
  loadingTaxDividend,
  loadingUniiShare,
  loadingEarnings,
  loadingRelatedIndexes,
  loadingTopHoldings,
  loadingGrowth10k,
  loadingPriceChartData,
  loadingReinvestedPriceData,
  loadingCashPriceData,
  loadingFundProfileData,
  loadingDividendHistory,

  shareClass,
  shareClassData,
  taxDividend,
  uniiShare,
  earnings,
  relatedIndexes,
  topHoldings,
  growth10k,
  priceChartData,
  reinvestedPriceData,
  cashPriceData,
  dividendHistory,
  fundProfileData,
} = useFundProfile();

// Dynamically compute `isLoading` based on loading states
const isLoading = computed(
  () =>
    loadingRelatedIndexes.value ||
    loadingShareClass.value ||
    loadingShareClassData.value
);

const route = useRoute();
const ticker = ref('');

const fetchData = async (ticker) => {
  if (ticker !== '') {
    await Promise.all([
      fetchShareClass(ticker),
      fetchShareClassData(ticker),
      fetchRelatedIndexes(ticker),
      fetchFundProfileData(ticker),
      fetchTaxDividend(ticker),
      fetchUniiShare(ticker),
      fetchEarnings(ticker),
      fetchTopHoldings(ticker),
      fetchGrowth10k(ticker),
      fetchPriceChartData(ticker),
      fetchReinvestedPriceData(ticker),
      fetchCashPriceData(ticker),
      fetchDividendHistory(ticker),
    ]);
  }
};

const getSortedItems = (dataType) => {
  const dataValues = shareClassData.value?.[dataType]?.data_value || {};
  const choices = shareClassData.value?.[dataType]?.data_type_choices || {};

  return Object.entries(dataValues)
    .filter(([, value]) => value > 0) // Remove zero and negative values
    .sort(([, valueA], [, valueB]) => valueB - valueA) // Sort descending
    .map(([key, value]) => ({
      value: formatNumber(value, 2),
      label: choices[key] || '',
    }));
};

const allBreakDownItems = computed(() =>
  [
    {
      title: 'CREDIT QUALITY',
      items: getSortedItems('credit_rating_breakdown'),
    },
    { title: 'Call Schedule', items: getSortedItems('bond_calls_breakdown') },
    {
      title: 'ASSET TYPE',
      items: getSortedItems('main_asset_computed_breakdown'),
    },
    {
      title: 'GEOGRAPHICAL EXPOSURE',
      items: getSortedItems('geographical_exposure'),
    },
    {
      title: 'INDUSTRY EXPOSURE',
      items: getSortedItems('industry_exposure'),
    },
  ].filter((item) => item.items.length > 0)
);

const firstBreakDownItems = computed(() => allBreakDownItems.value.slice(0, 2));
const secondBreakDownItems = computed(() => allBreakDownItems.value.slice(2));
const hasSecondBreakDownItems = computed(
  () => secondBreakDownItems.value.length > 0
);

// Fetch all data on mount
onBeforeMount(async () => {
  ticker.value = route.params?.ticker.toUpperCase() || '';
  await fetchData(ticker.value);
});

// Watch for changes to the ticker parameter
watch(
  () => route.params.ticker,
  async (newTicker) => {
    if (newTicker) {
      ticker.value = newTicker.toUpperCase();
      await fetchData(ticker.value);
    }
  }
);
</script>
