<template>
  <section class="tabbed-tables light">
    <ul class="nav nav-tabs light" role="tablist">
      <li
        class="nav-item"
        :class="{ active: activeYear === '1yr' }"
        role="presentation"
      >
        <a
          href="#"
          class="nav-link"
          :class="{ active: activeYear === '1yr' }"
          id="tab-1"
          data-bs-toggle="tab"
          role="tab"
          :aria-selected="activeYear === '1yr'"
          @click.prevent="setActiveYear('1yr')"
          >1 Yr</a
        >
      </li>
      <li
        class="nav-item"
        :class="{ active: activeYear === '3yr' }"
        role="presentation"
      >
        <a
          href="#"
          class="nav-link"
          :class="{ active: activeYear === '3yr' }"
          id="tab-1"
          data-bs-toggle="tab"
          role="tab"
          :aria-selected="activeYear === '3yr'"
          tabindex="-1"
          @click.prevent="setActiveYear('3yr')"
          >3 Yr</a
        >
      </li>
      <li
        class="nav-item"
        :class="{ active: activeYear === '5yr' }"
        role="presentation"
      >
        <a
          href="#"
          class="nav-link"
          :class="{ active: activeYear === '5yr' }"
          id="tab-1"
          data-bs-toggle="tab"
          role="tab"
          :aria-selected="activeYear === '5yr'"
          tabindex="-1"
          @click.prevent="setActiveYear('5yr')"
          >5 Yr</a
        >
      </li>
    </ul>
    <!-- /.nav.light -->
    <div class="tab-content">
      <div class="tab-pane fade show active" role="tabpanel" tabindex="0">
        <div class="table-responsive">
          <table class="table sidebar">
            <thead>
              <tr>
                <th colspan="2">Discount Data</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in discountData" :key="`item_${index}`">
                <th>
                  {{ item.key }}
                </th>
                <td>{{ item.value || 0 }}%</td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- /.table-responsive -->
      </div>
    </div>
    <!-- /.tab-content -->
  </section>
  <!-- /.tabbled-tables -->
</template>
<script setup>
import { computed, ref } from 'vue';

const props = defineProps({
  shareClassData: {
    type: Object,
    default: () => ({}), // Default value as an empty object
  },
});

const activeYear = ref('1yr');
const discountData = computed(() => {
  const { shareClassData } = props;

  if (activeYear.value === '1yr') {
    return [
      {
        key: 'Average Discount (1 Yr)',
        value: shareClassData.discount_avg_1yr?.data_value,
      },
      {
        key: 'Discount Low (1 Yr)',
        value: shareClassData.discount_low_1yr?.data_value,
      },
      {
        key: 'Discount High (1 Yr)',
        value: shareClassData.discount_high_1yr?.data_value,
      },
      {
        key: 'Discount Relative Range (1 Yr)',
        value: shareClassData.discount_rel_range_1yr?.data_value,
      },
    ];
  } else if (activeYear.value === '3yr') {
    return [
      {
        key: 'Average Discount (3 Yr)',
        value: shareClassData.discount_avg_3yr?.data_value,
      },
      {
        key: 'Discount Low (3 Yr)',
        value: shareClassData.discount_low_3yr?.data_value,
      },
      {
        key: 'Discount High (3 Yr)',
        value: shareClassData.discount_high_3yr?.data_value,
      },
      {
        key: 'Discount Relative Range (3 Yr)',
        value: shareClassData.discount_rel_range_3yr?.data_value,
      },
    ];
  } else {
    return [
      {
        key: 'Average Discount (5 Yr)',
        value: shareClassData.discount_avg_5yr?.data_value,
      },
      {
        key: 'Discount Low (5 Yr)',
        value: shareClassData.discount_low_5yr?.data_value,
      },
      {
        key: 'Discount High (5 Yr)',
        value: shareClassData.discount_high_5yr?.data_value,
      },
      {
        key: 'Discount Relative Range (5 Yr)',
        value: shareClassData.discount_rel_range_5yr?.data_value,
      },
    ];
  }
});

const setActiveYear = (year) => {
  activeYear.value = year;
};
</script>
